<template>
  <div>
    <div class="cos_Bg" style="background-image: url(img/cosmos/cos_bg.png);">
        <div class="cos_back" style="background-image: url(img/cosmos/cos_back.png);" @click="goback"></div>
        <div class="intro_box">
            <div class="cos_tit" style="background-image: url(img/cosmos/title1.png);" >
                <span>玩法介绍</span>
            </div>
            <div class="intro_txt">
                <div class="display_flex align-items_flex-start margin-bottom">
                    <p>1.</p>
                    <p class="flex_1">本玩法由【多乐平台&多乐App】（以下称 “平台”）提供，玩法相关的权利义务均由平台享有和承担。本规则所称“平台”，指本玩法接入并实际向用户提供的互联网平台。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>2.</p>
                    <p class="flex_1">用户参与玩法获取的随机礼物，送出时主播可以正常获得与普通礼物相同的分成，并可正常计入平台PK以及财富等级、魅力等级等相关权益，但是不计入平台小时榜、活动类等榜单或任务（具体榜单或任务根据平台实际运营情况可能有所变更，具体以平台实际为准）。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>3.</p>
                    <p class="flex_1">用户通过玩法获得的随机礼物仅限于在平台内消费，不得以任何形式兑换成法定货币、现金或其他任何具有交换价值的物品或服务，禁止主播、用户及其他第三方主体进行任何形式的针对随机礼物的交易，否则平台及/或开发者将严格按照相关法律规定、平台规则等对主播、用户采取相关管理措施，主播、用户应自行对此承担法律责任及相关损失。请用户谨防上当受骗。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>4.</p>
                    <p class="flex_1">因实现本玩法互动功能所需，平台可能向本玩法提供主播及用户帐号信息、互动信息，在玩法内以实时动态、榜单等方式展示。</p>
                </div>
            </div>
            <div class="cos_tit margin-top" style="background-image: url(img/cosmos/title1.png);" >
                <span>重要提示</span>
            </div>
            <div class="intro_txt">
                <div class="display_flex align-items_flex-start margin-bottom">
                    <p>1.</p>
                    <p class="flex_1">本玩法不向未成年人开放，未成年人用户请勿参与。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>2.</p>
                    <p class="flex_1">本玩法是提升用户直播间互动体验的功能，仅供娱乐交流使用。主播、用户及其他任何主体均不得以任何非法目的与方式（包括但不限于赌博、诈骗等）使用。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>3.</p>
                    <p class="flex_1">禁止使用本玩法实施任何影响互动公平性的行为，或利用产品BUG等不正当手法参与直播互动，一旦发生上述情 况，开发者及/或平台均有权取消发放对应奖励，追回奖励，对情节严重的，开发者及/或平台保留一切追究法律责任的权利。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>4.</p>
                    <p class="flex_1">消费中请注意保管好帐号、密码、短信验证号码等登录操作凭证，谨防上当受骗。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>5.</p>
                    <p class="flex_1">用户不得以任何不正当手段或舞弊的方式参与本活动， 一经发现，平台及/或开发者有权取消用户的参与资格，并有权收回用户获取的礼物及权益，同时保留追究其相关法律责任的权利。不正当手段及舞弊行为包括但不限于：下载非官方客户端；使用模拟器、插件、外挂等非法工具扫码、下 载、安装、注册、登录、使用；注册多个帐号；篡改设备数 据；恶意牟利等扰乱平台秩序；使用插件、外挂、系统或第三方工具对本平台及本活动进行干扰、破坏、修改或施加其他影响及平台认为的其他不正当手段。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>6.</p>
                    <p class="flex_1">该玩法仅供娱乐，用户获得的礼物不可反向兑换成现金或其他任何具有价值的商品，禁止一切线下交易，收购等不正当行为，平台将对各类以盈利为目的的交易行为进行严厉打击；</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>7.</p>
                    <p class="flex_1">活动期间，因用户操作不当或用户所在地区网络故障、支 付平台网络故障、电信运营商故障、第三方其他平台限制等 非平台所能控制的原因导致的用户无法参与活动、或参与失败，平台及/或开发者无需为此承担任何法律责任。</p>
                </div>
                <div class="display_flex align-items_flex-start margin-bottom-sm">
                    <p>8.</p>
                    <p class="flex_1">如因不可抗力、情势变更、相关政策变动、政府机关指 令要求等原因导致本功能玩法调整、暂停举办或无法进行的，开发者及/或平台有权随时决定修改、暂停、取消或终 止本活动，并无需为此承担任何法律责任。</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/cosmos.css"
import {newUser} from "@/api/api";
export default {
  name: 'cosmosIntroduce',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    goback(){
        this.$router.go(-1)
    }
  }
}
</script>
